import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import "./payment.scss";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useLocation, useNavigate,  } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import {
  isIOS,
  isAndroid,
  isMobile,
  isTablet,
  isIPad13,
} from "react-device-detect";
import Accordion from "react-bootstrap/Accordion";
import {
  useElements,
  useStripe,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import card from "./img/card.png";
import apay from "./img/apple.svg";
import gpay from "./img/google.png";
import { getPaymentIntentId } from "../api/stripe/stripeApi";
import { getClientKey } from "../actions/stripe/getClientKeyAction";

function Payment({ getClientKey, clientkey }) {
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [showApplePayButton, setShowApplePayButton] = useState(false);
  const [showGooglePayButton, setShowGooglePayButton] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const takeaway_name = localStorage.getItem("takeaway_name") || "";
  const [selectedMethod, setSelectedMethod] = useState("");
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [showStripeButtons, setShowStripeButtons] = useState(false);
  const [takeawayName, setTakeawayName] = useState();
  const [error, setError] = useState(null);
  const Apikey = localStorage.getItem("apikey");
  const [amount, setAmount] = useState(
    parseFloat(localStorage.getItem("amount"))
  );

  const [jsonResponse, setJsonResponse] = useState([]);

  const queryParams = new URLSearchParams(location.search);
  const cid = queryParams.get("client_id");

  const handleSelect = (method) => {
    setSelectedMethod(method);
    if (method == "google-pay" || method == "apple-pay") {
      setShowStripeButtons(true);
    }
  };

  useEffect(() => {
    if (isIOS) {
      handleSelect("apple-pay");
      setShowApplePayButton(true);
      setShowGooglePayButton(false);
      setSelectedMethod("apple-pay");
    } else if (isAndroid || isTablet) {
      handleSelect("google-pay");
      setShowGooglePayButton(true);
      setShowApplePayButton(false);
      setSelectedMethod("google-pay");
    } else {
      setShowGooglePayButton(false);
      setShowApplePayButton(false);
      setSelectedMethod("credit-card");
    }
  }, []);

  useEffect(() => {
    if (cid != null) {
      const post = {
        shopId: cid,
        action: "getClientApiKey",
      };
      getClientKey(post);
    }
  }, [cid]);

  useEffect(() => {
    if (takeaway_name !== "" && takeaway_name !== "undefined") {
      setTakeawayName(
        capitalizeFirstLetter(localStorage.getItem("takeaway_name"))
      );
      // } else {
      //   navigate("");
    }
  }, [takeaway_name]);

  useEffect(() => {
    const amountCheck = localStorage.getItem("amount") || 0;
    if (stripe && amountCheck && takeawayName != "") {
      const amt = Math.round(amountCheck * 100);
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "gbp",
        total: {
          label: takeawayName ? takeawayName : "",
          amount: amt,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      pr.canMakePayment()
        .then((result) => {
          if (result) {
            setPaymentRequest(pr);
            pr.on("paymentmethod", async (ev) => {
              const amt = localStorage.getItem("amount");
              if (ev.paymentMethod.id && amt && parseFloat(amt) >= 0.5) {
                const postData = {
                  paymentMethodId: ev.paymentMethod.id,
                  action: "paymentCustom",
                  api_key: clientkey.clientkey.data.key,
                  amount: amt,
                };
                try {
                  const response = await getPaymentIntentId(postData);
                  const clientSecret = response?.data?.response || "";
                  if (response?.data?.status === true) {
                    const { error: confirmError } =
                      await stripe.confirmCardPayment(clientSecret);
                    if (confirmError) {
                      const postDataFailedvalue = {
                        paymentFailed: true,
                        action: "paymentCustom",
                        api_key: clientkey.clientkey.data.key,
                        amount: amt,
                      };
                      await getPaymentIntentId(postDataFailedvalue);
                      navigate("/failed");
                    } else {
                      const postDatavalue = {
                        paymentSuccess: true,
                        action: "paymentCustom",
                        api_key: clientkey.clientkey.data.key,
                        amount: amt,
                      };
                      const responseValue = await getPaymentIntentId(postDatavalue);
                      if(responseValue.status){
                        window.location.href = "/success";
                      }
                      else{
                        window.location.href = "/failed";
                      }
                      // navigate("/success");
                    }
                  } else {
                    const postDataFailedvalue = {
                      paymentFailed: true,
                      action: "paymentCustom",
                      api_key: clientkey.clientkey.data.key,
                      amount: amt,
                    };
                    await getPaymentIntentId(postDataFailedvalue);
                    navigate("/failed");
                  }
                } catch (error) {
                  // alert(error);
                  // setJsonResponse(error);
                  console.error("Error handling Google Pay payment:", error);
                  navigate("/failed");
                }
              }
            });
          }
        })
        .catch((error) => {
          alert(error);
        });
    }
  }, [stripe, amount, takeawayName]);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handlePayment = async (event) => {
    event.preventDefault();
    setButtonLoader(true);

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(
        CardExpiryElement,
        CardNumberElement,
        CardCvcElement
      ),
    });

    if (error) {
      alert(error.message);
      setButtonLoader(false);
    } else {
      const postData = {
        paymentMethodId: paymentMethod.id,
        action: "paymentCustom",
        api_key: clientkey.clientkey.data.key,
        amount: localStorage.getItem("amount"),
      };

      try {
        const response = await getPaymentIntentId(postData);
        const clientSecret = response?.data?.response || "";
        if (response.data.status === true) {
          const { error: confirmError } = await stripe.confirmCardPayment(
            clientSecret
          );
          if (confirmError) {
            const postDataFailedvalue = {
              paymentFailed: true,
              action: "paymentCustom",
              api_key: clientkey.clientkey.data.key,
              amount: localStorage.getItem("amount"),
            };
            await getPaymentIntentId(postDataFailedvalue);
            navigate("/failed");
          } else {
            const postDatavalues = {
              paymentSuccess: true,
              action: "paymentCustom",
              api_key: clientkey.clientkey.data.key,
              amount: localStorage.getItem("amount"),
            };
            const responseValues = await getPaymentIntentId(postDatavalues);
            
            if(responseValues.status){
              
              const orderId = localStorage.getItem("orderId");
              const encodedApiKey = localStorage.getItem("apikey");
              const Dine = localStorage.getItem("Dine") === "true"; 
              
              const postData = {
                  apiKey: encodedApiKey,
                  orderId: orderId,
              };
              
              if (Dine) {
                  postData.Dine = Dine;
              }
                        
              try {
                  const response = await fetch("https://api-new.fmb.eposapi.co.uk/stsupdateforqr", {
                      method: "POST",
                      headers: {
                          Accept: "application/json",
                          "Content-Type": "application/x-www-form-urlencoded", // Corrected to match JSON body
                      },
                      body: JSON.stringify(postData),
                  });
              
                  if (!response.ok) {
                      const errorDetails = await response.text();
                      throw new Error(`Error: ${response.status} - ${errorDetails}`);
                  }
              
                  const data = await response.json();
  
              } catch (err) {
                  console.error("Fetch Error:", err.message);
                  setError(err.message);
              }
              navigate("/success");
            }
            else{
              navigate("/failed");
            }
            
          }
        } else {
          const postDataFailedvalue = {
            paymentFailed: true,
            action: "paymentCustom",
            api_key: clientkey.clientkey.data.key,
            amount: localStorage.getItem("amount"),
          };
          await getPaymentIntentId(postDataFailedvalue);
          navigate("/failed");
        }
        setButtonLoader(false);
      } catch (error) {
        console.error("Error handling Google Pay payment:", error);
        const postDataFailedvalue = {
          paymentFailed: true,
          action: "paymentCustom",
          api_key: clientkey.clientkey.data.key,
          amount: localStorage.getItem("amount"),
        };
        await getPaymentIntentId(postDataFailedvalue);
        navigate("/failed");
      }
    }
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#4b4452",
        fontWeight: "600",
        fontSize: "18px",
        fontSmoothing: "antialiased",
      },
      invalid: {
        iconColor: "#e41010",
        color: "#e41010",
      },
    },
  };

 
  const orderId = localStorage.getItem("orderID");
  console.log(orderId);
  
  const encodedApiKey = localStorage.getItem("apikeY");
  console.log(encodedApiKey);

  function JsonDisplay({ data }) {
    // Format the JSON data with indentation for readability
    const formattedData = JSON.stringify(data, null, 2);

    return (
      <div>
        <h4>Formatted JSON Data</h4>
        <pre>{formattedData}</pre>
      </div>
    );
  }

  return (
    <section>
      <div id="payment-screen" className="payment-details">
        <Container>
          <Row className="justify-content-center">
            <div className="payment-screen">
              <div className="payment-header">
                <div className="head">
                  {Apikey  ? (
                    <Link to={`/all?client_id=${cid}&apiKey=${encodedApiKey}&orderId=${orderId}`} className="back">
                    <FaArrowLeft />
                  </Link>
                  ) : (
                    <Link to={`/?client_id=${cid}`} className="back">
                    <FaArrowLeft />
                  </Link>
                  )}
                  
                  <p>Payment options</p>
                </div>
              </div>
              <div className="payment-body">
                <p className="header">Select payment method</p>
                <div className="payment-options">
                  <ul className="list-group">
                    <li className="list-group-item">
                      <label className="list-label">
                        <input
                          className="radio-button"
                          type="radio"
                          name="paymentMethod"
                          value="credit-card"
                          checked={selectedMethod === "credit-card"}
                          onChange={() => handleSelect("credit-card")}
                        />
                        <div className="checkoutPayUI">
                          <div className="checkoutPayUIchild">
                            <img src={card} alt="" />
                            <h5>Credit or Debit Card</h5>
                          </div>
                        </div>
                      </label>
                      {selectedMethod === "credit-card" && (
                        <Accordion defaultActiveKey="0">
                          <div className="manual-card-payment">
                            <Row>
                              <Col
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                              >
                                <div className="card-details">
                                  <div className="title">Card Number</div>
                                  <CardNumberElement
                                    options={CARD_OPTIONS}
                                    className="custom-card-element"
                                  />
                                </div>
                              </Col>
                            </Row>
                            <div className="card-details-main">
                              <Row>
                                <Col xs={6} md={6} sm={6} lg={6} xl={6} xxl={6}>
                                  <div className="Security">
                                    <div className="title">Expires on</div>
                                    <CardExpiryElement
                                      options={CARD_OPTIONS}
                                      className="custom-card-element"
                                    />
                                  </div>
                                </Col>
                                <Col xs={6} sm={6} md={6} lg={6} xl={6} xxl={6}>
                                  <div className="expires">
                                    <div className="exp">Security code</div>
                                    <CardCvcElement
                                      options={CARD_OPTIONS}
                                      className="custom-card-element"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Accordion>
                      )}
                    </li>
                    {showApplePayButton && (
                      <>
                        <li className="list-group-item">
                          <label className="list-label">
                            <input
                              className="radio-button"
                              type="radio"
                              name="paymentMethod"
                              value="apple-pay"
                              checked={selectedMethod === "apple-pay"}
                              onChange={() => handleSelect("apple-pay")}
                            />
                            <div className="checkoutPayUI">
                              <div className="checkoutPayUIchild">
                                <img
                                  src={apay}
                                  alt="aPay"
                                  className="payment-logo"
                                />
                                <h5>Apple Pay</h5>
                              </div>
                            </div>
                          </label>
                        </li>
                      </>
                    )}
                    {showGooglePayButton && (
                      <>
                        <li className="list-group-item">
                          <label className="list-label">
                            <input
                              className="radio-button"
                              type="radio"
                              name="paymentMethod"
                              value="google-pay"
                              checked={selectedMethod === "google-pay"}
                              onChange={() => handleSelect("google-pay")}
                            />
                            <div className="checkoutPayUI">
                              <div className="checkoutPayUIchild">
                                <img
                                  src={gpay}
                                  alt="google-pay"
                                  className="payment-logo"
                                />
                                <h5>Google Pay</h5>
                              </div>
                            </div>
                          </label>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </div>
              {/* <div>
                <p>JsonFormate</p>
                <JsonDisplay data={jsonResponse} />
              </div> */}

              <div className="payment-button">
                {selectedMethod == "credit-card" && (
                  <>
                    <div className="payment">
                      <button className="proceed-icon">
                        <div className="pay-btn" onClick={handlePayment}>
                          {buttonLoader ? (
                            <>
                              <Spinner
                                animation="border"
                                variant="success"
                                size="md"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </Spinner>
                            </>
                          ) : (
                            "Confirm Payment"
                          )}
                        </div>
                      </button>
                    </div>
                  </>
                )}
                {selectedMethod != "credit-card" &&
                  paymentRequest &&
                  showStripeButtons && (
                    <PaymentRequestButtonElement
                      className="custom-payment-button"
                      options={{
                        paymentRequest,
                        style: {
                          paymentRequestButton: {
                            type: "default",
                            theme: "dark",
                            height: "50px",
                            borderRadius: "100px",
                          },
                        },
                      }}
                    />
                  )}
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  clientkey: state.clientkey,
});

const mapDispatchToProps = {
  getClientKey,
};

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
